import React, { Children, useState, useEffect } from "react"
import { useForm, FormProvider, appendErrors } from "react-hook-form"
import { ContactGoogleForm } from "./ContactMove"
import { TextInput, Button, DatePicker, Select } from "./ui"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import axios from "axios"
import dateFormat from "dateformat"
import { navigate } from "gatsby"
import { usePromiseTracker, trackPromise } from "react-promise-tracker"
import { Send, Mail, Phone, MapPin, Loader } from "react-feather"
import { motion } from "framer-motion"
import { ContactQuery_site_siteMetadata_contact } from "../pages/__generated__/ContactQuery"

const schema = yup.object().shape({
    name1: yup.string().required(`お客様のお名前を入力してください`),
    Eaddress: yup.string().required(`現在のご住所を入力してください`),
    telephone: yup
        .string()
        .matches(
            /^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/,
            "有効な電話番号を入力してください"
        )
        .required(`連絡先のお電話番号を入力してください`),
    email: yup
        .string()
        .email(`正しいメールアドレスを入力してください`)
        .required(`お客様のメールアドレスを入力してください`),
    Edate: yup.string().required(`現在の住所での終了日を指定してください`),
    move: yup.string().required(`どちらかを選択してください`),
    Saddress: yup.string().when("move", {
        is: "購読する",
        then: yup.string().required(`お引越し先の住所を入力してください`),
    }),
    Sdate: yup.date().when("move", {
        is: "購読する",
        then: yup
            .date()
            .when(
                "Edate",
                (Edate, schema) =>
                    Edate &&
                    schema.min(
                        Edate,
                        "現在の住所の配達終了日よりも後の日付を選択してください"
                    )
            )
            .required(`お引越し先での配達開始日を指定してください`),
    }),
    pay: yup.string().when("move", {
        is: "購読する",
        then: yup.string().required(`どちらかを選択してください`),
    }),
    other: yup.string().when("pay", {
        is: "その他",
        then: yup.string().required(`その他の支払方法を入力してください`),
    }),
})

const IconRight = ({ spin = false }) => {
    if (spin) {
        return (
            <span
                className="spin"
                style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    animationDuration: "5s",
                }}
            >
                <Loader />
            </span>
        )
    }
    return <Send />
}

type FormData = {
    name1: string
    name2: string
    Saddress: string
    telephone: string
    email: string
    Sdate: string
    move: string
    Eaddress: string
    Edate: string
    pay: string
    other: string
    message: string
    type: string
    store: string
    sendmail: string
    tel: string
    code: string
}

const Moving: React.FC<{
    data: ContactQuery_site_siteMetadata_contact
}> = ({ data }) => {
    const { errors, watch, ...methods } = useForm<FormData>({
        resolver: yupResolver(schema),
    })
    const [transactionState, setTransactionState] = useState(false)
    const { promiseInProgress } = usePromiseTracker()
    const move = watch("move")
    const pay = watch("pay")
    useEffect(() => {
        methods.setValue("type", "Moving")
        methods.setValue("store", "ニュースサービス日経亀戸")
        methods.setValue("sendmail", data.mail)
        methods.setValue("tel", data.phone)
        methods.setValue("storeAddress", data.address)
        methods.setValue("code", data.code)
    })
    const submit = values => {
        values.Sdate = dateFormat(values.Sdate, "isoDate")
        values.Edate = dateFormat(values.Edate, "isoDate")

        const GATEWAY_URL =
            "https://8ru9wphnlb.execute-api.ap-northeast-1.amazonaws.com/SendMail"
        trackPromise(
            fetch(GATEWAY_URL, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                body: JSON.stringify(values),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            })
                .then(response => {
                    if (response.ok) {
                        navigate(`/thanks`)
                    }
                })
                .catch(error => {
                    navigate(`/error`)
                })
        )
    }
    return (
        <FormProvider errors={errors} {...methods} watch={watch}>
            <h4 className="text-center text-2xl text-color-4">
                お引越しのご連絡
            </h4>

            <form
                method="POST"
                onSubmit={methods.handleSubmit(submit)}
                noValidate
            >
                <input type="hidden" name="type" ref={methods.register} />
                <input type="hidden" name="store" ref={methods.register} />
                <input type="hidden" name="sendmail" ref={methods.register} />
                <input type="hidden" name="tel" ref={methods.register} />
                <input
                    type="hidden"
                    name="storeAddress"
                    ref={methods.register}
                />
                <input type="hidden" name="code" ref={methods.register} />
                <TextInput
                    label="お名前・会社名(部署名)"
                    name="name1"
                    footer={errors.name1?.message}
                />
                <TextInput
                    label="担当者様のお名前"
                    name="name2"
                    footer={errors.name2?.message}
                />
                <TextInput
                    label="現在のご住所"
                    name="Eaddress"
                    footer={errors.Eaddress?.message}
                />
                <TextInput
                    label="お電話番号"
                    name="telephone"
                    footer={errors.telephone?.message}
                />
                <TextInput
                    label="メールアドレス"
                    name="email"
                    type="email"
                    footer={errors.email?.message}
                />
                <DatePicker
                    label="現住所の配達終了日を選択してください"
                    name="Edate"
                    type="date"
                    min={new Date()}
                    footer={errors.Edate?.message}
                />
                <Select
                    label="転居先でも購読なさいますか？"
                    name="move"
                    footer={errors.move?.message}
                    value={[
                        { id: 1, value: "購読する" },
                        { id: 2, value: "購読しない" },
                    ]}
                />
                {move === "購読する" && (
                    <>
                        <TextInput
                            label="新しいご住所"
                            name="Saddress"
                            footer={errors.Saddress?.message}
                        />
                        <DatePicker
                            label="転居先での配達開始日を選択してください"
                            name="Sdate"
                            type="date"
                            min={
                                methods.getValues("Edate")
                                    ? methods.getValues("Edate")
                                    : new Date()
                            }
                            footer={errors.Sdate?.message}
                        />
                        <Select
                            label="転居先でのお支払い方法を選択してください"
                            name="pay"
                            footer={errors.pay?.message}
                            value={[
                                { id: 1, value: "これまでと同じ" },
                                { id: 2, value: "その他" },
                            ]}
                        />
                        {pay === "その他" && (
                            <TextInput
                                label="その他の支払方法"
                                name="other"
                                footer={errors.other?.message}
                            />
                        )}
                    </>
                )}

                <TextInput label="通信欄" name="message" footer={""} />
                <div className="py-3 lg:p-4">
                    <Button
                        type="button,submit"
                        title="送信"
                        disabled={promiseInProgress}
                        iconRight={<IconRight spin={promiseInProgress} />}
                        aria-label="送信"
                    />
                </div>
            </form>
        </FormProvider>
    )
}

export default Moving
